import abi from "./contract/GlobalPool.json";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import Buy from "./component/buy"
import './style.css';
import "./copy.png";
import "./prefix.png";
import Buy3 from "./component/buy3";
import './assets/css/bootstrap.css';
import './assets/js/bootstrap.js';

function App() {
  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[level,setLevel]=useState("None");
  const[wlevel,setLevelw]=useState("None");
  const[plevel,setLevelp]=useState("None");
  const[pack,setpackage]=useState("None");
  const[pack3,setpackage3]=useState("None");
  const[pack1,setpackage1]=useState("None");
  const[refer,setrefer]=useState("None");
const [tasks,setTasks] = useState([]);
const [tasks1,setTasks1] = useState([]);
const [tt,settt]=useState("None");
const [tt4,settt4]=useState("None");
const[dd,setDD]=useState("None");
const[dd1,setDD1]=useState("None");
const[dd2,setDD2]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[upd,setupd]=useState("None");
const [tt1,settt1]=useState("None");
const [roi,seroi]=useState("None");
const [roi1,seroi1]=useState("None");
const [roi2,seroi2]=useState("None");
const [a1,sea1]=useState("None");
const [a2,sea2]=useState("None");
const [a3,sea3]=useState("None");
const [tt2,settt2]=useState("None");
const [tt3,settt3]=useState("None");

  useEffect(() => {
    
    const connectWallet = async () => {
      
      const contractAddress = "0x602a970df62c63c9bc0a9cab6445c5009bb55f72";
      const contractABI = abi.abi;
     
      const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('ref');
    console.log(myParam);
    document.querySelector("#name").value=myParam;
if(myParam == null)
  {
    document.querySelector("#name").value="0x1590E4f46580CBD8fBFFF4137629cdfBc4bB090b";
  }
  
  try {
    const { ethereum } = window;
  //  document.querySelector("#test").value = "0";
    if (ethereum) {
      const account = await ethereum.request({
        method: "eth_requestAccounts",
       
      });
     
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      window.ethereum.on("accountsChanged", () => {
        
        window.location.reload();
      });
          const provider = new ethers.providers.Web3Provider(ethereum);
        
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
        
        
        
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );
          setAccount(account);
          setState({ provider, signer, contract });
          document.querySelector("#us").value  = account[0];
      let alltasks = await contract.getMemos(account[0]);
      setTasks(alltasks);
      let alltasks1 = await contract.getMemos1(account[0]);
      setTasks1(alltasks1);
      const b =Number(alltasks[2]);
      const refer = (alltasks[1]);
     setrefer(refer);
     document.querySelector("#hid").value  = (alltasks[1]);
     const direct =Number(alltasks[2]);
     setDirect(direct);
     const directs = Number(alltasks[3])/1000000000000000000;
     setDirects(directs);
     const level = Number(alltasks[4])/1000000000000000000;
     setLevel(level);

     const wlevel = Number(alltasks[13])/1000000000000000000;
     setLevelw(wlevel);

     const plevel = Number(alltasks[12])/1000000000000000000;
     setLevelp(plevel);
     
    
     const pack = Number(alltasks[6])/1000000000000000000 ;
     

setpackage(pack);
const pack3 = Number(alltasks[14])/1000000000000000000 ;
     

setpackage3(pack3);


const pack1 = Number(alltasks[5]) ;
document.querySelector("#pp").value  = Number(alltasks[5]);


setpackage1(pack1);



     setB(b);

     const upd = (Number(alltasks[7]) ) /1000000000000000000  ;
          
     setupd(upd);

     const dd = Number(alltasks[9]);
          
     setDD(dd);

     const dd2 = Number(alltasks[9]);
          
     setDD2(dd2);

     const dd1 =  Date.now() / 1000;
     setDD1(dd1);


     const tt1 = Math.trunc((dd1 - dd) / (3660 * 24 )) ;
   //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
     
     settt1(tt1);

     const tt2 = Math.trunc((dd1 - dd2) / (3600 * 24  )) ;
     //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
       
       settt2(tt2);
      
       document.querySelector("#tt2").value = tt2;
       
       

       
    // const roi1 = Number(alltasks[9]) / 100000;
          
    // seroi1(roi1);

    

    // document.querySelector("#wi1").value = roi2;
     
     const a1 = Number(alltasks1[0])-1;
          
     sea1(a1);
     const a3 = Number(alltasks1[2])/1000000000000000000;
          
     sea3(a3);
     const a2 = Number(alltasks1[1])/1000000000000000000;
     sea2(a2);   
     var rr = 0;
     const tt4 =  Number(alltasks[15])/1000000000000000000;
     settt4(tt4);
     
     const tt =  level  + directs +wlevel + plevel;
     settt(tt);
     const tt3 = Number(alltasks[10])/1000000000000000000;
     settt3(tt3);
     document.querySelector("#bal").value = tt3;
     const referlink = 'https://prefixcoin.com/?ref='+ account;
     setreflink(referlink);
     document.querySelector("#link").value =referlink;

console.log(pack);
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
   
    
    connectWallet();
    
    function myFunction() {
      
      var copyText = "hello";
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      
    
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.valueOf);
    }

  }, []);

 
  // console.log(state);
  return (
  <div>
    <input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
    <input type="hidden" id ="tt2"></input>
    <input type="hidden" id ="wi1"></input>
    <input type="hidden" id ="roi"></input>
    <input type="hidden" id ="bal"></input>
<div class="se-pre-con"></div>
  
<header>



<div class="main-menu">
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
        <div class="container">
          <a href="#" class="logo">
          <img src= "prefix.png" width="250px" height="250px"></img>
          </a>
         
        </div>
      </nav>
</div>


</header>



<div class="banner-area">
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-md-6">
        <div class="banner-txt">
          <h2>WELCOME
TO <span>PREFIX COIN</span></h2>
          <p>Secured by cryptographic principles,
ensuring transparency and immutability.
          </p>
          <ul>
            <li><i class="las la-check-circle"></i> Blockchain decentralized and anonymous platform  </li>
            <li><i class="las la-check-circle"></i> Totally secure income based on  DAO smart-contract</li>
            <li><i class="las la-check-circle"></i> Smart-contract verified and audited by independent company  </li>
          </ul>
          <a href="#">My Account : {account}</a>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="col-md-4">
<a href="#" class="foot-btn">1 PFC : {a3}</a>

          <a href="#" class="foot-btn">
          <input type ="text" id ="test" width={"200px"} ></input>
                            <br></br><br></br>
                            <Buy state={state}  />
                            </a>
          <a href="#" class="foot-btn">

          <input type ="text" id ="test1" width={"200px"} ></input>
                            <br></br><br></br>
                            <Buy3 state={state}  /> 
          </a>
        </div>
        <br></br>
<div class="container">
    <div class="row">
        <div class="title">
            <h3>ACCOUNT<span></span><span>DETAILS</span></h3>
            
        </div>
    </div>



<div class="row">
  <div class="col-md-4">
    <div class="fund-box">
      <h3>MY TOKENS     </h3>
      <p>   {pack} PFC</p>
   
      <h3>FREEZE AIRDROP TOKENS      </h3>
      <p>  {pack3} PFC </p>

      <h3>MY PURCHASE    </h3>
      <p>  {pack1} USDT </p>
   
    </div>
  </div>



  <div class="col-md-4">
    <div class="fund-box">
    <h3>DIRECT TEAM    </h3>
      <p>  {direct} </p>
      <h3>DIRECT INCOME    </h3>
      <p>  {directs} USDT </p>
   
      <h3>LEVEL INCOME    </h3>
      <p>  {level} USDT </p>
   


    </div>
  </div>


  <div class="col-md-4">
    <div class="fund-box">

       <h3>LEADERSHIP INCOME
       </h3>
      <p>  {wlevel} USDT </p>
      <h3>PERFORMANCE INCOME
      </h3>
      <p>  {plevel} USDT </p>
   
      <h3>CURRENT WEEK BONUS
      </h3>
      <p>  {tt4} USDT </p>

    </div>
  </div>
  

   
</div>



</div>




<div class="container">

  <div class="row mt-4">
    <div class="title">
    <h3>TOTAL WITHDRAW

       </h3>
      <p>  {upd} USDT </p>
      <h3>TOTAL COIN WITHDRAW

      </h3>
      <p>  {tt3} USDT </p>
   
      <h3>TOTAL BONUS
      </h3>
      <p>  {tt} USDT </p>
        
    </div>
</div>


<div class="row mt-2 mb-3 justify-content-center">

  <div class="col-md-6" >

    <div class="step-1">
    <div class="step-title">
      REFER LINK

    </div>
   <div class="step-box">
<a href="">
    {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
         navigator.clipboard.writeText(referlink);}} /></button></a>
   </div>


   <div class="step-box">
    <h3>MY REFER</h3>
    <a href="#">{refer}</a>
   </div>
  </div>


  <div class="step-2">
   <div class="step-title">
    PREFIX FEATURES
  </div>

  <div class="step-box">
    <h3>You will receive airdrop of 50 % tokens on
listing on panswapcake</h3>
    <p>Join Refferal Rewards Programme with Prefix Coin </p>
    <p>You will receive 5% of Total sales of PREFIX COIN if your are earning 50 USDT every week</p>
    <p>Built on a robust blockchain framework, Capable of handling high transaction 
      volumes with minimal fees.</p>
      <p>
Prefix Coin can be used for Payments, staking, DeFi, tokenized assets, and more.
  </p>

    

    <a href="#">
    Introducing the groundbreaking PreFix Coin, revolutionizing the realm of cryptocurrency with an innovative algorithmic approach. Our pioneering concept dynamically adjusts the token's price with each purchase and sale, creating a self-sustaining ecosystem of value growth. With every new acquisition, the token's price ascends, incentivizing investment and fostering a thriving community. Moreover, even sales contribute to price appreciation, ensuring a balanced and rewarding experience for all participants. This algorithmic design instills confidence and stability, transcending traditional models and empowering investors with a sustainable avenue for wealth accumulation. Join us as we redefine the possibilities of digital assets with PreFix Coin.</a>
   </div>
  </div>













  </div>


</div>
</div>










    <footer>
      <div class="container">
      <div class="row align-items-center">
        <div class="col-md-2">

          <a href="#" class="logo">
            <span>P</span> FC
          </a>
          
        </div>
  
       
  
        
  

        <div class="col-md-6">
          <p class="copyright">© 2024 All rights reserved <span>PrefixCoin</span></p>
        </div>

      </div>
  
  
    </div>
    </footer>
  


<br></br>
<br></br>
</div>  
  );
}

export default App;